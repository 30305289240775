@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

:root {
  --loader-color: var(--primary-500);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;

  &.bars,
  &.bars::before,
  &.bars::after {
    background: var(--loader-color);
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }

  &.bars {
    color: var(--loader-color);
    text-indent: -9999em;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }

  &.bars:before,
  &.bars:after {
    position: absolute;
    top: 0;
    content: "";
  }

  &.bars:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &.bars:after {
    left: 1.5em;
  }

  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}

.loading,
.loadingMore {
  position: relative;
  min-height: 10rem;
}

.loading::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingMore::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin loadingSimple {
  .loading,
  .loadingMore {
    &::after {
      box-sizing: border-box;
      z-index: 2;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border-top: 2px solid var(--loader-color);
      border-right: 2px solid transparent;
      animation: spinner 0.6s linear infinite;
    }
  }

  .loadingMore {
    bottom: 1rem;
  }
}

@mixin loadingDots {
  .loading,
  .loadingMore {
    &::after {
      color: var(--loader-color);
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      text-indent: -9999em;
      animation: load4 1s infinite linear;
    }
  }

  .loadingMore {
    padding-bottom: 10rem !important;

    &::after {
      bottom: 4rem;
    }
  }
}

@mixin loadingEllipses {
  .loading,
  .loading:before,
  .loading:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loading {
    color: var(--loader-color);
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loading:before,
  .loading:after {
    content: "";
    position: absolute;
    top: 0;
  }
  .loading:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loading:after {
    left: 3.5em;
  }
}
